<script setup lang="ts">
const route = useRoute()

useHead({
  title: 'О компании',
  meta: [
        { name: 'description', content: `С момента своего основания в 1989 году, мы с гордостью представляем собой 
        надежного партнера на рынке. В нашем ассортименте представлено более 300 товаров.` }
    ],
})
</script>

<template>
  <div style="max-width: 1200px; margin: 0 auto;">
    <h1>О компании</h1>
    <p>Добро пожаловать в нашу компанию!</p>

    <p>С момента своего основания в 1989 году, мы с гордостью представляем собой надежного партнера на рынке. Более
      30
      лет успешной работы подтверждают нашу приверженность качеству и инновациям.</p>

    <p><b>Наши ценности:</b></p>

    <ul>
      <li>Профессионализм: В нашей команде работают настоящие эксперты своего дела, которые вкладывают душу в каждое
        изделие.</li>
      <li>Качество: Мы гарантируем, что каждая деталь нашей продукции соответствует высоким стандартам. Качество —
        это
        не просто слово для нас, это основа нашей работы.</li>
      <li>Разнообразие: В нашем ассортименте представлено более 300 наименований товаров, что позволяет
        удовлетворить
        потребности самых различных клиентов и рынков.</li>
    </ul>

    <p><b>Почему выбирают нас?</b></p>

    <ul>
      <li>Опыт: За годы работы мы накопили уникальные знания и навыки, которые позволяют нам предлагать лучшие
        решения
        для наших клиентов.</li>
      <li>Индивидуальный подход: Мы ценим каждого клиента и стремимся предложить именно то, что соответствует вашим
        требованиям.</li>
      <li>Инновации: Мы постоянно обновляем наш ассортимент и внедряем новые технологии, чтобы оставаться на шаг
        впереди.</li>
    </ul>

    <p>Выбирая нашу компанию, вы выбираете качество, надежность и профессионализм. Мы готовы к новым вызовам и уверены,
      что вместе с вами сможем достичь новых высот!</p>

    <p style="text-align: center;"><img src="/img/main-page/products.jpeg" width="100%" style="max-width: 795px;" /></p>
  </div>

</template>

<style>
p {
  text-align: justify;
}
</style>
